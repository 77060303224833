import { Guid } from '@/common/models/Guid';
import { utcDateOrNow } from '@/common/utils/DateFunctions';

/**
 * Immutable representation of the current active span.
 */
export class SessionSpan {
  id: Guid;

  sessionV2Id: Guid;
  cardId?: Guid;
  startedAt: Date;
  constructor(props?: Partial<SessionSpan>) {
    props = props || {};
    this.id = Guid.valueOrNew(props.id);
    this.sessionV2Id = Guid.valueOrUndefined(props.sessionV2Id);
    this.cardId = Guid.valueOrUndefined(props.cardId);
    this.startedAt = utcDateOrNow(props.startedAt);
  }

  isSameSpan(sessionV2Id: Guid, cardId?: Guid) {
    return (
      Guid.areEqual(this.sessionV2Id, sessionV2Id) &&
      Guid.areEqual(this.cardId, cardId)
    );
  }
}
