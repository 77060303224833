import { FrontSessionV2MaxCollectTimeMs } from 'common/models/user-sessions-v2/Front/FrontSessionV2MaxCollectTimeMs';
import { immerable } from 'immer';

import { Guid } from '@/common/models/Guid';
import { utcDateOrNow, utcDateOrUndefined } from '@/common/utils/DateFunctions';

import { SpanSlice } from './SpanSlice';

export class ActivitySlice {
  [immerable] = true;
  sessionV2Id: Guid;
  startedAt: Date;
  endedAt?: Date;
  spanSlices: SpanSlice[];

  constructor(props?: Partial<ActivitySlice>) {
    props = props || {};
    this.sessionV2Id = Guid.valueOrUndefined(props.sessionV2Id);
    this.startedAt = utcDateOrNow(props.startedAt);
    this.endedAt = utcDateOrUndefined(props.endedAt);
    this.spanSlices = props.spanSlices || [];
  }

  end(date: Date) {
    if (date < this.startedAt) {
      date = this.startedAt;
    }

    // make sure the endedAt is limited such that the slice is a max duration of 15s
    if (
      date.getTime() - this.startedAt.getTime() >
      FrontSessionV2MaxCollectTimeMs
    ) {
      date = new Date(
        this.startedAt.getTime() + FrontSessionV2MaxCollectTimeMs
      );
    }

    this.endedAt = date;
    this.spanSlices[this.spanSlices.length - 1]?.end(date);
  }

  recordSpanSlice(spanId: Guid, cardId: Guid | undefined, date: Date) {
    const latestSpanSlice = this.spanSlices[this.spanSlices.length - 1];

    if (latestSpanSlice && !latestSpanSlice.endedAt) {
      if (
        Guid.areEqual(spanId, latestSpanSlice?.spanId) &&
        Guid.areEqual(cardId, latestSpanSlice?.cardId)
      ) {
        return;
      }

      latestSpanSlice.end(date);
    }

    this.spanSlices.push(new SpanSlice({ spanId, cardId, startedAt: date }));
  }

  static startNewSlice(
    spanId: Guid,
    sessionV2Id: Guid,
    startedAt: Date,
    cardId?: Guid
  ) {
    const activityEvent = new ActivitySlice({ sessionV2Id, startedAt });
    activityEvent.recordSpanSlice(spanId, cardId, startedAt);
    return activityEvent;
  }

  compact() {
    return {
      i: this.sessionV2Id,
      s: this.startedAt,
      e: this.endedAt,
      z: this.spanSlices.map((x) => x.compact())
    };
  }
}
