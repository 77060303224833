import {
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState
} from 'react';
import isEqual from 'react-fast-compare';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
  useFormState,
  useWatch
} from 'react-hook-form';

import { useEffectSkipInitial } from '@/common/hooks/useEffectSkipInitial';
import { useEvent } from '@/common/hooks/useEvent';

import { FormHiddenSubmit } from '../FormHiddenSubmit';
import { Form, FormProps } from './Form';

export interface FormHookProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<FormProps, 'onSubmit' | 'defaultValue'> {
  defaultValues: DefaultValues<TFieldValues>;
  onSubmit: (values: TFieldValues) => void;
  onInit?: (methods: UseFormReturn<TFieldValues>) => void;
  withHiddenSubmit?: boolean;
  stopPropagationOnSubmit?: boolean;
  onDirtyChange?: (isDirty: boolean) => void;
}

function _FormHook<TFieldValues extends FieldValues = FieldValues>(
  {
    defaultValues,
    children,
    onSubmit,
    onInit,
    onDirtyChange,
    withHiddenSubmit = true,
    stopPropagationOnSubmit,
    ...rest
  }: FormHookProps<TFieldValues>,
  ref?: ForwardedRef<HTMLFormElement>
) {
  const hasRunInit = useRef(false);
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
    shouldFocusError: true
  });

  if (!hasRunInit.current) {
    hasRunInit.current = true;
    onInit?.(methods);
  }

  const hasOnDirtyChange = !!onDirtyChange;

  return (
    <FormProvider {...methods}>
      <Form
        {...rest}
        ref={ref}
        onSubmit={(e) => {
          if (stopPropagationOnSubmit) {
            e.stopPropagation();
          }
          methods.handleSubmit(onSubmit)(e);
        }}
      >
        {hasOnDirtyChange && <_DirtyListener onChange={onDirtyChange} />}
        {children}
        {withHiddenSubmit && <FormHiddenSubmit />}
      </Form>
    </FormProvider>
  );
}

type DirtyListenerProps = {
  onChange: (value: boolean) => void;
};
const _DirtyListener: FC<DirtyListenerProps> = ({ onChange }) => {
  const formState = useFormState();
  const { reset } = useFormContext();
  formState.isSubmitSuccessful;

  const [defaultValues, setDefaultValues] = useState(
    JSON.parse(JSON.stringify(formState.defaultValues ?? {}))
  );

  const currentValues = useWatch();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(currentValues);
      setDefaultValues(JSON.parse(JSON.stringify(currentValues ?? {})));
    }
  }, [formState.isSubmitSuccessful]);

  const handleChange = useEvent(onChange);

  const isDirty = !isEqual(
    defaultValues,
    JSON.parse(JSON.stringify(currentValues))
  );
  useEffectSkipInitial(() => {
    handleChange(isDirty);
  }, [isDirty]);

  return null;
};

export const FormHook = forwardRef(_FormHook) as <
  TFieldValues extends FieldValues = FieldValues
>(
  props: FormHookProps<TFieldValues> & { ref?: ForwardedRef<HTMLFormElement> }
) => ReturnType<typeof _FormHook>;
