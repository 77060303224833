import {
  __InputWrapperProps as MantineInputWrapperBaseProps,
  Input as MantineInput,
  MantineSize,
  PinInput as MantinePinInput,
  PinInputProps as MantinePinInputProps
} from '@mantine/core';
import React from 'react';

import { useEvent } from '@/common/hooks/useEvent';

import { IAmInputRenderProps, InputRenderer } from '../Renderers/InputRenderer';

export interface FormPinInputProps
  extends Omit<
      MantinePinInputProps,
      'onFocus' | 'labelProps' | 'error' | 'size'
    >,
    Omit<MantineInputWrapperBaseProps, 'labelProps' | 'descriptionProps'>,
    Omit<
      IAmInputRenderProps<HTMLInputElement>,
      'onFocus' | 'size' | 'error' | 'hideErrorMessage'
    > {
  size?: MantineSize | 'xxl';
}

export const FormPinInput = React.forwardRef<any, FormPinInputProps>(
  (
    {
      selectAllTextOnFocus,
      autoFocus,
      description,
      descriptionProps,
      inputContainer,
      showSkeleton,
      labelProps,
      size,
      error,
      required,
      label,
      w,
      onComplete,
      ...rest
    },
    ref
  ) => {
    const handleComplete = useEvent(onComplete);

    return (
      <InputRenderer
        ref={ref as any}
        autoFocus={autoFocus}
        error={error}
        size={size as any}
        selectAllTextOnFocus={selectAllTextOnFocus}
        showSkeleton={showSkeleton}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        labelProps={labelProps}
      >
        {({ error, ...inputRest }, ref) => (
          <MantineInput.Wrapper
            withAsterisk={required}
            label={label}
            w={w}
            size={(size as any) === 'xxl' ? 'xl' : size}
            description={description}
            error={error}
            {...inputRest}
          >
            <MantinePinInput
              ref={ref as any}
              {...rest}
              w={w}
              size={size as any}
              error={!!error}
              onComplete={handleComplete}
            />
          </MantineInput.Wrapper>
        )}
      </InputRenderer>
    );
  }
);
