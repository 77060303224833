import {
  orderByMostVisibleThenByMostRecentDescending,
  VisibleCard
} from '@/common/components/Card/shared/VisibilityTracking/types';
import { Guid } from '@/common/models/Guid';
import { ActivitySlice } from '@/common/models/user-sessions-v2/Front/ActivitySlice';
import { FrontSessionV2 } from '@/common/models/user-sessions-v2/Front/FrontSessionV2';
import { SessionSpan } from '@/common/models/user-sessions-v2/Front/SessionSpan';
import { DataDogServiceHelper } from '@/common/utils/DataDogService';
import { sortByDateAscending } from '@/common/utils/DateFunctions';

import {
  CardModalLayer,
  IFrontSessionStore,
  IFrontSessionStoreComputed
} from './_types';

export const computeState = (
  state: IFrontSessionStore
): IFrontSessionStoreComputed => {
  const topModalLayer = getTopModalLayer(state);
  return {
    focusedModalCardId: topModalLayer?.cardId,
    focusedModalType: topModalLayer?.modalType,
    focusedHubCardId: getMostVisibleCardId(state)
  };
};

export const updateActivity = (
  state: IFrontSessionStore,
  date: Date,
  endActivity: boolean = false
) => {
  if (!state.session) {
    return;
  }

  const currentCardId = state.focusedCardId;

  if (
    !state.currentSpan ||
    !state.currentSpan.isSameSpan(state.session.id, currentCardId)
  ) {
    state.currentSpan = new SessionSpan({
      sessionV2Id: state.session.id,
      cardId: currentCardId,
      startedAt: date
    });
  }

  const latestSlice = state.activitySlices[state.activitySlices.length - 1];

  if (latestSlice && !latestSlice.endedAt) {
    if (Guid.areEqual(latestSlice?.sessionV2Id, state.session.id)) {
      latestSlice.recordSpanSlice(state.currentSpan.id, currentCardId, date);
      return;
    }

    latestSlice.end(date);
  }

  if (endActivity) {
    return;
  }

  const newSlice = ActivitySlice.startNewSlice(
    state.currentSpan.id,
    state.session.id,
    date,
    currentCardId
  );
  state.activitySlices.push(newSlice);
};

export const debounceFocusedCardChanges = (
  state: IFrontSessionStore,
  onUpdateFocusedCardId: () => void,
  timeoutMs: number
) => {
  const candidateCardId =
    getTopModalLayer(state)?.cardId || getMostVisibleCardId(state);

  const shouldQueueFocusChange = !Guid.areEqual(
    state._candidateFocusedCardId,
    candidateCardId
  );

  state._candidateFocusedCardId = candidateCardId;

  if (shouldQueueFocusChange) {
    // the only way to update the focusedCardID is if the candidate card ID has been stable for 1s
    clearTimeout(state._debounceTimeout);
    state._debounceTimeout = setTimeout(
      () => onUpdateFocusedCardId(),
      timeoutMs
    );
  }
};

export const prepareForCollection = (state: IFrontSessionStore, date: Date) => {
  const latestSlice = state.activitySlices[state.activitySlices.length - 1];
  if (latestSlice && !latestSlice.endedAt) {
    latestSlice.end(date);
  }
};

export const prepareForCollectionPost = (state: IFrontSessionStore) => {
  state.activitySlices = [];
};

export const resetAfterFailedCollection = (
  state: IFrontSessionStore,
  activitySlices: ActivitySlice[]
) => {
  state.activitySlices = [...activitySlices, ...state.activitySlices].sort(
    (a, b) => sortByDateAscending(a.startedAt, b.startedAt)
  );
};

export const handleSessionChange = (
  state: IFrontSessionStore,
  sessionV2Id?: Guid
) => {
  if (Guid.areEqual(state.session?.id, sessionV2Id)) {
    return;
  }

  state.session = new FrontSessionV2({ id: sessionV2Id });

  DataDogServiceHelper.trySetSessionV2Information(sessionV2Id);
};

export const handleCardVisibilityChange = (
  state: IFrontSessionStore,
  isVisible: boolean,
  { cardId, timeVisible, visibilityLevel }: VisibleCard
) => {
  if (!isVisible) {
    state.visibleCards = state.visibleCards.filter(
      (x) => !Guid.equals(x.cardId, cardId)
    );

    return;
  }

  const existing = state.visibleCards.find((x) => x.cardId.equals(cardId));
  if (existing) {
    existing.timeVisible = timeVisible;
    existing.visibilityLevel = visibilityLevel;
    return;
  }

  state.visibleCards.push({
    cardId,
    timeVisible,
    visibilityLevel
  });
};

export const handleModalLayerChange = (
  state: IFrontSessionStore,
  isVisible: boolean,
  date: Date,
  { layerId, cardId, modalType }: Omit<CardModalLayer, 'startedAt'>
) => {
  if (!isVisible) {
    state.cardModalLayers = state.cardModalLayers.filter(
      (x) => x.layerId !== layerId
    );

    return;
  }

  const existing = state.cardModalLayers.find((x) => x.layerId === layerId);

  if (existing) {
    existing.cardId = cardId;
    existing.modalType = modalType;
    return;
  }

  state.cardModalLayers.unshift({
    layerId,
    cardId,
    modalType: modalType,
    startedAt: date
  });
};

export const getTopModalLayer = (
  state: IFrontSessionStore
): CardModalLayer | undefined => state.cardModalLayers[0];

export const getMostVisibleCardId = (state: IFrontSessionStore) => {
  if (!state.visibleCards || !state.visibleCards.length) {
    return undefined;
  }

  return [...state.visibleCards].sort(
    orderByMostVisibleThenByMostRecentDescending
  )[0].cardId;
};
