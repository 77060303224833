import { FrontSessionV2MaxCollectTimeMs } from 'common/models/user-sessions-v2/Front/FrontSessionV2MaxCollectTimeMs';
import { immerable } from 'immer';

import { Guid } from '@/common/models/Guid';
import { utcDateOrNow, utcDateOrUndefined } from '@/common/utils/DateFunctions';

export class SpanSlice {
  [immerable] = true;
  spanId: Guid;
  cardId?: Guid;
  startedAt: Date;
  endedAt?: Date;
  // todo - new slice for modal (and modal types) vs card

  constructor(props?: Partial<SpanSlice>) {
    props = props || {};
    this.spanId = Guid.valueOrNew(props.spanId);
    this.cardId = Guid.valueOrUndefined(props.cardId);
    this.startedAt = utcDateOrNow(props.startedAt);
    this.endedAt = utcDateOrUndefined(props.endedAt);
  }

  end(date: Date) {
    if (date < this.startedAt) {
      date = this.startedAt;
    }

    // make sure the endedAt is limited such that the slice is a max duration of 15s
    if (
      date.getTime() - this.startedAt.getTime() >
      FrontSessionV2MaxCollectTimeMs
    ) {
      date = new Date(
        this.startedAt.getTime() + FrontSessionV2MaxCollectTimeMs
      );
    }

    this.endedAt = date;
  }

  compact() {
    return {
      i: this.spanId,
      c: this.cardId,
      s: this.startedAt,
      e: this.endedAt
    };
  }
}
