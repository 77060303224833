import { immerable } from 'immer';

import { Guid } from '@/common/models/Guid';

export class FrontSessionV2 {
  [immerable] = true;
  id: Guid;
  constructor(props?: Partial<FrontSessionV2>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrUndefined(props.id);
  }
}
